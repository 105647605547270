import { useSearchParams } from '@remix-run/react'
import { useCallback } from 'react'
import type { ZodEnum, ZodString, z } from 'zod'

export const useTypedSearchParam = <
  T extends z.ZodTypeAny,
  K extends ZodString | ZodEnum<[string, ...string[]]>
>(
  key: string,
  {
    serializeSchema,
    deserializeSchema
  }: {
    serializeSchema: K
    deserializeSchema: T
  }
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentValue = deserializeSchema.parse(
    searchParams.get(key)
  ) as z.infer<T>

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const setParam = useCallback((value: unknown) => {
    const safeValue = serializeSchema.parse(value)
    searchParams.set(key, safeValue)
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const clearParam = useCallback(() => {
    searchParams.delete(key)
    setSearchParams(searchParams, { replace: true })
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
  const toggleParam = useCallback(
    (value: unknown) => {
      const safeValue = serializeSchema.parse(value)
      if (currentValue === safeValue) return clearParam()
      setParam(safeValue)
    },
    [currentValue]
  )

  return {
    value: currentValue,
    set: setParam,
    clear: clearParam,
    toggle: toggleParam
  }
}
